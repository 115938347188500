@import 'variables';
@import '../common/mixins';

.companyLogo {
	&_6R {
		width: 160px;
		height: 25px;
	}
}

.additional {
	.companyLogo {
		&_6R {
			margin: 0 auto;
		}
	}
}


.racenumber {
	iata-logo {
		display: flex;
	}
	&__companyLogo {
		display: block;
		margin-right: auto;
	}
	&__logo {
		display: flex;
		flex-direction: row-reverse;

		> div:last-of-type {
			margin-right: auto;
		}
	}
}

